
import Vue from '@/vueTyped';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { InputHTMLAttributes } from 'vue';

export default Vue.extend({
    props: {
        capture: {
            type: String as () => InputHTMLAttributes['capture'],
            default: undefined,
        },
    },

    computed: {
        useCapacitorPhotoSelection(): boolean {
            return ['ios', 'android'].includes(this.$store.state.platform);
        },
    },

    methods: {
        async handleButtonClick() {
            // TODO: De-duplicate from ImageInput.vue?
            try {
                const { dataUrl } = await Camera.getPhoto({
                    webUseInput: true,
                    source: CameraSource.Prompt,
                    presentationStyle: 'popover',
                    resultType: CameraResultType.DataUrl,
                });

                if (dataUrl) {
                    const type = dataUrl.split(';')[0].split(':')[1];
                    const extension = type.split('/')[1];
                    const fileName = `${Date.now()}.${extension}`;
                    const file = await fetch(dataUrl)
                        .then(response => response.arrayBuffer())
                        .then(buffer => new File([buffer], fileName, { type }));

                    this.$emit('change', file);
                }
            } catch (error) {
                console.error(error);
            }
        },

        async handleFileInputChange() {
            if (!(this.$refs.fileInput instanceof HTMLInputElement)) return;
            const file = this.$refs.fileInput.files?.[0];
            if (!file) return;
            this.$emit('change', file);
        },
    },
});
