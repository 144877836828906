import router from '@/router';

const MAIN_SITE_ORIGIN = 'https://www.iseechange.com';

export function interceptExplicitInternalLinks() {
    addEventListener('click', (event: MouseEvent) => {
        if (!(event.target instanceof Element)) return;

        const closestLink = event.target.closest<HTMLAnchorElement>('a');
        if (!closestLink) return;

        const isExplicitInternalLink = closestLink.getAttribute('href')?.startsWith(MAIN_SITE_ORIGIN);
        if (!isExplicitInternalLink) return;

        // Now we're pretty sure we've got an internal link _not_ handled by <router-link>,
        // e.g. from CMS content, so we'll prevent the navigation and pass it to the router manually.

        event.preventDefault();

        const path = closestLink.href.slice(closestLink.origin.length);
        console.info('Intercepting explicit internal link', path);
        router.push(path);
    });
}
