import { render, staticRenderFns } from "./Web.layout.vue?vue&type=template&id=e6d0c130&scoped=true"
import script from "./Web.layout.vue?vue&type=script&lang=ts"
export * from "./Web.layout.vue?vue&type=script&lang=ts"
import style0 from "./Web.layout.vue?vue&type=style&index=0&id=e6d0c130&prod&lang=css"
import style1 from "./Web.layout.vue?vue&type=style&index=1&id=e6d0c130&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6d0c130",
  null
  
)

export default component.exports