
import UserAvatar from '@/components/UserAvatar.vue';
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';

export default Vue.extend({
    components: {
        UserAvatar,
    },

    props: {
        icons: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        location(): string {
            if (!this.currentUser?.addressComponents) return '';
            try {
                return Array.from(new Set([
                    this.currentUser.addressComponents.city,
                    this.currentUser.addressComponents.place,
                    this.currentUser.addressComponents.state,
                    this.currentUser.addressComponents.country === 'United States' ? null : this.currentUser.addressComponents.country,
                ].filter(Boolean))).join(', ');
            } catch (error) {
                return this.currentUser.shortAddress || '--';
            }
        },
    },

    methods: {
        async logout() {
            await this.$store.dispatch('accountLogOut');
        },
    },
});
