
import Vue from '@/vueTyped';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import OverlayModal from '@/layouts/OverlayModal.vue';
import SurveyContent from './Content.vue';
import { CurrentUser, Survey } from '@/types';
import AuthButton from '../AuthButton.vue';

export default Vue.extend({
    i18n: {
        messages: {
            en: {
                signInRequired: 'Sign in to complete the survey.',
                hasRequest: 'You’ve been requested to complete a survey.',
                accept: 'Take the survey',
                decline: 'Decline',
            },

            es: {
                signInRequired: 'Inicie sesión para completar la encuesta.',
                hasRequest: 'Se le ha solicitado que complete una encuesta.',
                accept: 'Tomar la encuesta',
                decline: 'Rechazar',
            },
        },
    },

    components: {
        LoadingIndicator,
        OverlayModal,
        SurveyContent,
        AuthButton,
    },

    data() {
        return {
            loading: 0,
            surveyId: '', // Persists beyond the query params so we can close the modal gracefully
            embedFrameHeight: '300px',
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        survey(): Survey | null {
            return this.$store.getters.survey(this.surveyId) ?? null;
        },

        modalIsOpen: {
            get(): boolean {
                return Boolean(this.$route.query.survey);
            },

            set(modalIsOpen) {
                // Only handle the `false` case. The dialog opens via the query param.
                if (!modalIsOpen) {
                    const currentLocation = this.$router.resolve(this.$route.fullPath, this.$route).location;

                    this.$router.push({
                        ...currentLocation,
                        query: {
                            ...currentLocation.query,
                            survey: undefined,
                        },
                    });
                }
            },
        },

        embed(): NonNullable<Survey['settings']>['embed'] | null {
            return this.survey?.settings?.embed ?? null;
        },
    },

    watch: {
        'currentUser.id'(userId) {
            // Surveys won't change between users, but access might
            // depend on being signed in and having a SurveyRequest.
            if (userId && this.surveyId && !this.survey) {
                this.fetchSurvey(this.surveyId);
            }
        },

        '$route.query.survey': {
            immediate: true,
            handler(routeQuerySurvey) {
                if (routeQuerySurvey) {
                    this.surveyId = routeQuerySurvey;
                } else {
                    setTimeout(() => {
                        // Give the modal a chance to close, then clear out the ID
                        // if a new one hasn't been set in the meantime.
                        if (!this.$route.query.survey) {
                            this.surveyId = '';
                        }
                    }, 500);
                }
            },
        },

        surveyId: {
            immediate: true,
            async handler(surveyId) {
                if (surveyId) {
                    this.fetchSurvey(surveyId);
                }
            },
        },

        'survey.settings.embed.height'(height: string | undefined) {
            if (height) {
                this.embedFrameHeight = height;
            }
        },
    },

    mounted() {
        addEventListener('message', this.handleMessage);
    },

    beforeDestroy() {
        removeEventListener('message', this.handleMessage);
    },

    methods: {
        async fetchSurvey(surveyId: Survey['id']) {
            try {
                this.loading += 1;
                await this.$store.dispatch('fetchSurvey', surveyId);
            } finally {
                this.loading -= 1;
            }
        },

        handleMessage(event: MessageEvent) {
            if (typeof event.data !== 'object') return;
            if (!('embeddedFormHeight' in event.data)) return;
            this.embedFrameHeight = event.data.embeddedFormHeight;
        },
    },
});
